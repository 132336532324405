/* Search Input Styles */
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input[type="search"]::-webkit-input-placeholder {
  color: "#1D1D1D" !important;
  opacity: 1 !important;
}

input[type="search"]::-moz-placeholder {
  color: "#1D1D1D" !important;
  opacity: 1 !important;
}

.pb-3 {
  padding-bottom: 24px;
}

.clickable-text {
  text-decoration: underline;
  cursor: pointer;
  color: #551a8b !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-ul-offset_4_hover:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Cropper Styles */
.rounded .cropper-view-box,
.rounded .cropper-face {
  border-radius: 50% !important;
}

.rectangle .cropper-view-box,
.rectangle .cropper-face,
.square .cropper-view-box,
.square .cropper-face {
  border-radius: 0% !important;
}

/* Text Clamp */
.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.text-clamp-1 {
  -webkit-line-clamp: 1;
}

.text-clamp-2 {
  -webkit-line-clamp: 2;
}

.text-clamp-3 {
  -webkit-line-clamp: 3;
}

.text-clamp-4 {
  -webkit-line-clamp: 4;
}

.text-clamp-11 {
  -webkit-line-clamp: 11;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-4 {
  margin-left: 4px !important;
}

/* Single Post - Article, Podcast, Business Styles - START */

#articleContent img,
#articleContent video,
#articleContent div {
  width: 100% !important;
  height: auto;
}
#articleContent iframe {
  width: 100% !important;
}

#articleContent figure {
  margin: 0;
}

#articleContent a {
  position: static !important;
}

/* END */

.rdrCalendarWrapper {
  width: 100%;
  max-width: 100%;
}

.rdrMonth {
  max-width: 100%;
}

/* Custom scrollbar*/
.custom-pref-scrollbar::-webkit-scrollbar {
  width: 10px;
}
.custom-pref-scrollbar::-webkit-scrollbar-button {
  display: none;
}
.custom-pref-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.custom-pref-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #d7d7d7;
}
.custom-pref-scrollbar::-webkit-scrollbar-thumb:active {
  background: #979797;
}
